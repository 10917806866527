import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import './course-styles.css'

import Layout from '../components/layout'
import PriceCard from '../components/price-card.js'
import DescriptionCard from '../components/course-description-card.js'
import VimeoPlayer from '../components/vimeo-player.js'
import YoutubePlayer from '../components/youtube-player.js'
import EpisodeList from '../components/episode-list.js'

export default props => {
    const { data } = props
    const course = data.course

    return (
        <Layout dontFixHeader={true}>
            <SEO title={course.title} description={course.description} />
            <div className="md:flex justify-center sm:px-2 max-w-7xl mx-auto">
                <div className="sm:mt-2 flex-1 max-w-3xl">
                    <div className="sticky top-0 z-10 bg-white py-2 rounded shadow my-1 sm:my-1">
                        <div className="w-full px-4 md:flex md:flex-row-reverse md:items-center md:justify-between">
                            <div className="flex items-center text-gray-600">
                                <svg
                                    className="w-5 h-5"
                                    viewBox="0 0 28 28"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M23.2167 22.4L18.2 14L23.2167 5.6L25.6667 14L23.2167 21.4ZM12.1333 21.5833L5.83333 15.4H15.8667L20.8833 23.8L12.1333 21.5833ZM12.1333 6.3L20.8833 4.08333L15.8667 12.4833H5.71667C5.71667 12.6 12.1333 6.3 12.1333 6.3ZM24.9667 0L13.5333 2.91667L11.7833 5.83333H8.4L0 14L8.4 22.1667H11.7833L13.4167 25.0833L24.85 28L27.8833 16.9167L26.25 14L28 11.0833L24.9667 0Z"
                                        fill="currentColor"
                                    />
                                </svg>
                                <span className="pl-1 font-bold ">Unity</span>
                            </div>
                            <h2 className="text-2xl font-semibold">{course.title}</h2>
                        </div>
                    </div>
                    <div className="relative pb-2/3 bg-white w-auto rounded-b shadow-md sm:py-1 sm:px-1">
                        {course.vimeoTrailerId ? (
                            <VimeoPlayer
                                className="absolute inset-0 sm:static w-full h-full rounded sm:vimeo"
                                id={course.vimeoTrailerId}
                            />
                        ) : (
                            <YoutubePlayer
                                className="absolute inset-0 sm:static w-full h-full rounded sm:vimeo"
                                id={course.youtubeTrailerId}
                            />
                        )}
                    </div>
                    <div className="mt-2 px-4 w-full pt-4 pb-1 rounded shadow bg-white">
                        <DescriptionCard course={course} />
                    </div>
                    <div className="md:hidden">
                        <div className="md:ml-2 md:sticky md:top-0 pt-2">
                            <PriceCard course={course} />
                        </div>
                    </div>
                    <div className="mt-2 px-4 w-full py-2 bg-white">
                        <EpisodeList course={course} />
                    </div>
                </div>
                <div className="hidden md:block flex-no-shrink">
                    <div className="md:ml-2 md:sticky md:top-0 pt-2">
                        <PriceCard course={course} />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query($id: String!) {
        course(id: { eq: $id }) {
            id
            title
            sku
            price
            description
            releaseDate
            isFree
            url
            difficulty
            videoDuration
            episodeCount
            vimeoTrailerId
            youtubeTrailerId
            childrenEpisode {
                url
                isFree
                slug
                title
            }
            bannerImg {
                childImageSharp {
                    fixed(width: 220) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
        site {
            siteMetadata {
                siteUrl
            }
        }
    }
`
