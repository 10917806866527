import React from 'react'
import {Link} from 'gatsby'

export default props => {
    const { course } = props
    return (
        <div className="bg-white">
            <h2 className="pb-2 text-xl font-semibold">Episodes</h2>
            <ul className="max-w-3xl">
                {course.childrenEpisode &&
                    course.childrenEpisode.map((e, i) => {
                        return (
                            <li
                                key={e.slug}
                                className="block mx-auto my-2 shadow-sm hover:shadow rounded overflow-hidden">
                                <Link
                                    className="block sm:flex justify-between px-4 py-4 bg-gray-100 "
                                    to={`/courses/${course.id}/${e.slug}`}>
                                    <span>
                                        {i + 1}. {e.title}
                                    </span>
                                    {e.isFree && (
                                        <span className="inline-block sm:flex sm:justify-center sm:items-center text-teal-700 bg-teal-200 rounded-full px-3 text-sm font-medium">
                                            Free
                                        </span>
                                    )}
                                </Link>
                            </li>
                        )
                    })}
            </ul>
        </div>
    )
}
