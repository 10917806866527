import { useSession } from '../util/context.js'
import { FirebaseContext } from 'gatsby-plugin-firebase'
import { Link, navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import React from 'react'
import Spinner from '../components/spinner.js'
import PurchaseBanner from '../components/purchase-banner.js'
import { useInView } from 'react-intersection-observer'

const PUBLISHABLE_KEY = 'pk_test_sJMiTwbbingZ4XZiaYmstdJR00p8ztfN2m'

export default props => {
    const loc = useLocation()
    const { origin } = loc
    const q = new URLSearchParams(loc.search)
    const shouldGoToPurchase = q.get('purchase')

    const [ref, inView] = useInView({ threshold: 0 })

    const fb = React.useContext(FirebaseContext)
    const { course } = props
    const { user } = useSession()
    const [loading, setLoading] = React.useState(true)
    const handleResult = (data, data2) => {
        console.log('DATA', data)
        console.log('DATA2', data2)
    }

    const purchaseEnabled = !!course.sku || course.isFree //If no sku then don't let users buy or free item
    const disabledClass = purchaseEnabled
        ? 'bg-gray-800 focus:bg-gray-900 hover:bg-gray-700'
        : 'bg-gray-500 cursor-not-allowed'

    const [hasPurchased, setHasPurchased] = React.useState(false)

    React.useEffect(() => {
        //@Todo investigate if this doesn't cause havoc in future
        if (course.isFree) return //No redirection if course is free

        if (!user || !fb) return
        if (shouldGoToPurchase) {
            goToPaymentPage()
        }
    })

    React.useEffect(() => {
        if (course.isFree) {
            setLoading(false)
            return
        }

        if (!user || !fb) {
            setLoading(false)
            setHasPurchased(false)
            return
        }
        const hasCourse = async () => {
            setLoading(true)
            const resp = await fb
                .firestore()
                .collection('users')
                .doc(user.uid)
                .collection('courses')
                .doc(course.id)
                .get()
            setHasPurchased(resp.exists)
            setLoading(false)
        }
        hasCourse()
    }, [fb, user, course])

    const goToPaymentPage = () => {
        const stripe = window.Stripe(PUBLISHABLE_KEY)
        let firstFreeEpisode = ''
        let courseId = ''
        if (course) {
            if (course.childrenEpisode && course.childrenEpisode.length > 0) {
                firstFreeEpisode = course.childrenEpisode[0].slug
            }
            courseId = course.id
        }
        const payload = {
            items: [{ sku: course.sku, quantity: 1 }],
            successUrl:
                origin +
                `/purchase-success?courseId=${courseId}&next=${firstFreeEpisode}&session_id={CHECKOUT_SESSION_ID}`,
            cancelUrl: origin + `/purchase-failed?courseId=${courseId}`,
        }

        if (user) {
            payload.customerEmail = user.email
        }
        console.log('Prepared payload', payload)
        stripe.redirectToCheckout(payload).then(handleResult)
    }

    let firstFreeEpisode = course.childrenEpisode[0]
    if (!firstFreeEpisode.isFree) {
        for (let i = 0; i < course.childrenEpisode.length; i++) {
            const e = course.childrenEpisode[i]
            if (e.isFree) {
                firstFreeEpisode = e
                break
            }
        }
    }

    const handlePurchaseClick = e => {
        if(course.isFree){
            navigate(course.childrenEpisode[0].url)
            return
        }

        setLoading(true)
        if (!user) {
            navigate(`/login?redirectTo=/courses/${course.id}?purchase=true`)
            return
        }
        goToPaymentPage()
    }
    return (
        <>
            {!hasPurchased && purchaseEnabled && (
                <div className={`${inView ? 'opacity-0' : 'opacity-100'} relative z-50 transition duration-300`}>
                    <PurchaseBanner
                        loading={loading}
                        price={course.price}
                        title={course.title}
                        onClick={handlePurchaseClick}
                    />
                </div>
            )}
            <div className="mx-auto w-full flex flex-col justify-between sm:flex-row md:flex-col text-left bg-white shadow md:shadow-xl rounded-lg overflow-hidden">
                <div className="px-4 sm:px-6 md:px-10 pt-8 ">
                    <h2 className="font-semibold text-gray-900 text-lg">Access this Course</h2>
                    <div className="pt-2 pb-2 border-b-2 border-gray-100">
                        <span className="text-teal-500 font-semibold uppercase tracking-wide text-sm">
                            What's Included
                        </span>
                    </div>
                    <ul className="font-medium sm:text-sm pb-8">
                        {!course.isFree && (
                            <li className="mt-4 flex items-center">
                                <div className="flex-center h-6 w-6 text-teal-700 rounded-full bg-teal-200">
                                    <svg
                                        className="h-4 w-4"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M5 13l4 4L19 7"
                                        />
                                    </svg>
                                </div>
                                <span className="ml-3 ">Lifetime access to this course</span>
                            </li>
                        )}
                        <li className="mt-4 flex items-center">
                            <div className="flex-center h-6 w-6 text-teal-700 rounded-full bg-teal-200">
                                <svg
                                    className="h-4 w-4"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M5 13l4 4L19 7"
                                    />
                                </svg>
                            </div>
                            <span className="ml-3 ">6+ hrs of video tutorials</span>
                        </li>
                        <li className="mt-4 flex items-center">
                            <div className="flex-center h-6 w-6 text-teal-700 rounded-full bg-teal-200">
                                <svg
                                    className="h-4 w-4"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M5 13l4 4L19 7"
                                    />
                                </svg>
                            </div>
                            <span className="ml-3 ">Project files and resources</span>
                        </li>
                        <li className="mt-4 flex items-center">
                            <div className="flex-center h-6 w-6 text-teal-700 rounded-full bg-teal-200">
                                <svg
                                    className="h-4 w-4"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M5 13l4 4L19 7"
                                    />
                                </svg>
                            </div>
                            <span className="ml-3 ">Private discord access</span>
                        </li>
                    </ul>
                </div>
                <div className="mt-6 sm:mt-0 md:mt-6 relative bg-gray-50 px-2 sm:px-6 pt-4 sm:pt-8 pb-8 sm:pb-12 w-auto sm:w-1/2 md:w-auto md:flex-1 flex flex-col justify-center text-center">
                    <Spinner loading={loading} />
                    {!loading &&
                        (hasPurchased ? (
                            <div className="flex flex-col items-center">
                                <div className="h-8 w-8 bg-teal-100 text-teal-900 rounded-full flex-center">
                                    <svg
                                        className="h-4 w-4"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M5 13l4 4L19 7"
                                        />
                                    </svg>
                                </div>
                                <p className="mt-3 py-2 flex-center text-sm text-gray-600">
                                    You own this course
                                </p>
                                <Link
                                    to={`/courses/${course.id}/${course.childrenEpisode[0].slug}`}
                                    className={`w-full py-4 sm:py-3 font-medium text-white rounded-md shadow ${disabledClass}
focus:outline-none focus:shadow-outline sm:text-sm sm:leading-5 transform duration-100 scale-100 active:scale-95`}>
                                    Resume Learning
                                </Link>
                            </div>
                        ) : (
                            <>
                                <h2 className="font-bold text-lg text-center">
                                    {course.isFree ? 'Free Course' : 'Pay once, own it forever'}
                                </h2>
                                <div ref={ref} className="flex justify-center items-center py-2">
                                    <span className="text-4xl font-medium text-gray-700">$</span>
                                    <h2 className="text-5xl font-bold text-gray-800">
                                        {course.price}
                                    </h2>
                                    <span className="pl-2 text-xl text-gray-500 font-medium">
                                        USD
                                    </span>
                                </div>
                                {course.isFree ? (
                                    <Link
                                        className={`group w-full mx-auto flex-center max-w-md py-4 sm:py-3 font-medium text-white rounded shadow ${disabledClass}
focus:outline-none focus:shadow-outline sm:text-sm sm:leading-5`}
                                        to={course.childrenEpisode[0].url}>
                                        Start Now
                                        <svg
                                            className="transform duration-150 group-hover:translate-x-2 w-4 h-4 ml-2"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M14 5l7 7m0 0l-7 7m7-7H3"
                                            />
                                        </svg>
                                    </Link>
                                ) : (
                                    <>
                                        <button
                                            disabled={!purchaseEnabled}
                                            onClick={handlePurchaseClick}
                                            className={`group w-full mx-auto flex-center max-w-md py-4 sm:py-3 font-medium text-white rounded shadow ${disabledClass}
focus:outline-none focus:shadow-outline sm:text-sm sm:leading-5 transform duration-100 scale-100 active:scale-95`}>
                                            {purchaseEnabled ? 'Get Access' : 'Coming Soon'}

                                            <svg
                                                className="transform duration-150 group-hover:translate-x-2 w-4 h-4 ml-2"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor">
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                                                />
                                            </svg>
                                        </button>
                                        <span className="mt-3 flex-center text-sm text-gray-600">
                                            <svg
                                                className="w-6 h-6 text-gray-500 mr-1"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor">
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                                                />
                                            </svg>
                                            <span className="">15 day refund</span>
                                        </span>
                                        <Link
                                            className="text-sm font-medium underline py-4 text-gray-600 hover:text-gray-900"
                                            to={`/courses/${course.id}/${firstFreeEpisode.slug}`}>
                                            Or view sample episodes
                                        </Link>
                                    </>
                                )}
                            </>
                        ))}
                </div>
            </div>
        </>
    )
}
