import React from 'react'
import Spinner from '../components/spinner.js'

export default ({ title, price, onClick, loading }) => {
    return (
        <div className="fixed bottom-0 left-0 right-0 z-50 mb-4 sm:mb-8">
            <div className="block max-w-8xl mx-auto px-4 lg:px-8">
                <div className="py-3 pl-6 pr-3 rounded-lg bg-gray-900 shadow-lg">
                    <div className="flex items-center justify-between flex-wrap">
                        <div className="w-full flex-1 flex items-center sm:w-0">
                            <p className="text-gray-200 truncate">
                                <strong className="font-medium text-white sm:hidden">
                                    {price === 0
                                        ? `Free Course. Start Now`
                                        : `Get Access for $${price}`}
                                </strong>
                                <span className="hidden sm:inline font-medium text-white">
                                    {price === 0
                                        ? `Start this Free Course `
                                        : `Get Lifetime Access to `}
                                    
                                    <span className="font-semibold text-teal-200">{title}</span>{' '}
                                </span>
                                <span className="hidden lg:inline">
                                    + private chat support and more.
                                </span>
                            </p>
                        </div>
                        <div className="flex-shrink-0 pl-12">
                            <div className="rounded-md shadow-sm">
                                <button
                                    onClick={onClick}
                                    className="relative flex items-center justify-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded text-gray-900 bg-white hover:text-gray-600 focus:outline-none focus:shadow-outline transition ease-in-out duration-75">
                                    <span className="hidden sm:inline">Get access for ${price} →</span>
                                    <span className="sm:hidden w-5 h-5">
                                        {loading ? (
                                            <Spinner loading={loading} />
                                        ) : (
                                            <svg
                                                className="w-5 h-5"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor">
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                                                />
                                            </svg>
                                        )}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
