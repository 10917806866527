import React from 'react'
import Vimeo from '@vimeo/player'
import { navigate } from 'gatsby'
import { useLocalStorage } from '../hooks/localstorage.js'

export default props => {
    const { id, episode, autoplay, className, onReady } = props

    const [volume, setVolume] = useLocalStorage('volume', 1)

    const ref = React.useRef()

    React.useEffect(() => {
        const player = new Vimeo(ref.current)

        const playNext = () => episode.next && navigate(episode.next)

        //@Todo show some indicator of next player in 3, 2, 1 ...
        //@Todo persist volume
        const onEnded = data => setTimeout(playNext, 2000)
        player.on('ended', onEnded)

        const onVolumeChanged = ({ volume }) => setVolume(volume)
        player.on('volumechange', onVolumeChanged)

        if (onReady) onReady(player)

        player.setVolume(volume)
        const cleanup = () => {
            player.off('ended', onEnded)
            player.off('volumechange', onVolumeChanged)
        }
        return cleanup
    // }, [ref, onReady, episode, setVolume])
    })

    //@Todo these values configurable?
    const vimeoId = id ? id : episode.vimeoId
    const url = `https://player.vimeo.com/video/${vimeoId}?title=0&byline=0&portrait=0&autoplay=${
        autoplay ? '1' : '0'
    }`
    return (
        <iframe
            title="course video"
            ref={ref}
            className={`${className || 'z-10 w-full h-full'}`}
            src={url}
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
        />
    )
}
