import React from 'react'
export default props => {
    const { course } = props
    return (
        <div className="bg-white max-w-3xl">
            <h2 className="pb-2 text-xl font-semibold">Course Description</h2>
            <p className="text-gray-800 text-sm sm:text-base">{course.description}</p>
            <div className="mt-auto sm:flex sm:justify-between py-4">
                <ul className="sm:flex w-full text-teal-700 text-sm text-gray-700 font-medium">
                    <li className="capitalize py-2 flex items-center pr-4">
                        <div className="flex-center h-7 w-7 rounded-full bg-teal-200">
                            <svg
                                className="h-4 w-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                                />
                            </svg>
                        </div>
                        <span className="ml-2">{course.difficulty} Difficulty</span>
                    </li>
                    <li className="capitalize py-2 sm:ml-4 flex items-center pr-4">
                        <div className="flex-center h-7 w-7 rounded-full bg-teal-200">
                            <svg
                                className="h-4 w-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                        </div>
                        <span className="ml-2">{course.videoDuration} Video</span>
                    </li>
                    <li className="capitalize py-2 sm:ml-4 flex items-center pr-4">
                        <div className="flex-center h-7 w-7 rounded-full bg-teal-200">
                            <svg
                                className="h-4 w-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
                                />
                            </svg>
                        </div>
                        <span className="ml-2">{course.episodeCount} Episodes</span>
                    </li>
                </ul>
            </div>
        </div>
    )
}
