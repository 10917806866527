import React from 'react'

export default props => {
    const { episode, className , id, autoplay} = props

    const ref = React.useRef()

    React.useEffect(() => {
        if (!episode || !episode.youtubeId) return

        //@Todo pending, find if we can get events on YT iframe
    }, [episode, ref])

    const url = `https://www.youtube.com/embed/${id || episode.youtubeId}?&autoplay=${
        autoplay ? '1' : '0'
    }&rel=0`

    return (
        <iframe
            className={`${className || 'w-full h-full'}`}
            title="video-iframe"
            src={url}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        />
    )
}
